import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/pagetitle"

const TechnologyPage = () => (
  <Layout>
    <PageTitle title="Technology Services" page="PageTitleAbout" />
    <Seo title="Our Technologies"
         description="We support a wide range of general purpose software systems and technologies to build custom applications. We build, upgrade and extend custom software." />
    <div className="content-page-wrapper">
      <div className="page-intro">
        <h2>Software Development</h2>

        <p><span style={{fontWeight:700}}>Software:</span> C#, Java, Python, Powershell, Ansible, PHP, Scala, Ruby on Rails, VB, C++, SQL, Angular, React</p>
        <p><span style={{fontWeight:700}}>Databases:</span> SQL Server, Oracle, Postgres, Mysql, MariaDB, Couchbase, CouchDB, MongoDB, Hadoop, Redshift.</p>
        <p><span style={{fontWeight:700}}>AWS:</span> S3, EC2, RDS, Elastic Beanstalk, Cloud Formations, Elastic Container Registry, Lambda, Load Balancers, Cloud Watch, Route53, VPN, VPC, SNS, SRS, Glue.</p>
        <p><span style={{fontWeight:700}}>Azure:</span> DevOps, SqlAzure, Websites, Azure Storage, Virtual Machines, Container Instances, Kubernetes</p>
        <p><span style={{fontWeight:700}}>Server Tech:</span> Elasticsearch, Solr, Redis, Kubernetes, Docker, Kafka, Nifi.</p>
        <p>We have also used 100s or other technologies for workflow, analytics, deployments, web design, web development, source control, project management, software development, testing, hosting and maintenance.</p>
        <h3>Full-Stack engineering including:</h3>
        <ul>
          <li>Requirements analysis and design</li>
          <li>Requirements collection, analysis and refinement</li>
          <li>Design and modeling services for data centric applications</li>
          <li>Software Architecture including state diagrams, logic and flow diagrams meant to represent a physical system.</li>
        </ul>
        <h3>Software Development</h3>
        <ul>
          <li>coding</li>
          <li>unit testing</li>
          <li>scripting</li>
          <li>source control, versioning</li>
        </ul>
        <h3>
          Deployment
        </h3>
        <ul>
          <li>setup of development, testing, integration and production environments.</li>
          <li>pipeline setup for continuous delivery</li>
          <li>automated execution of tests in the pipeline</li>
          <li>container based delivery of software</li>
          <li>setup of elastic deployment environments</li>
          <li>networking, access control and security required for public facing applications</li>
        </ul>


        <h3>Testing services</h3>
        <ul>
          <li>functional and regression testing of software artifacts in various environments</li>
          <li>automated UI and Unit tests</li>
          <li>test documentation</li>
        </ul>
        <hr/>

        <h2>Cloud Engineering</h2>

        <h3>Requirements</h3>
        <ul>
          <li>client meetings</li>
          <li>document review</li>
          <li>security review</li>
          <li>Reporting / NOC requirements (network operations center)</li>
        </ul>
        <h3>Design</h3>
        <ul>
          <li>Dev, Test, Integration and Production environments design</li>
          <li>network architecture</li>
          <li>security</li>
          <li>access controls</li>
          <li>Software analysis</li>
          <li>fail-over / fault tolerance / elasticity</li>
        </ul>

        <h3>Development</h3>
        <ul>
          <li>Automation coding</li>
          <li>Source repository</li>
          <li>Software Evaluation setup</li>
        </ul>

        <h3>Deployment / Infrastructure setup</h3>
        <ul>
          <li>Dev, Test, Integration and Production environments implementation</li>
          <li>Multi availability zones</li>
          <li>Fail environment setup</li>
          <li>Security infrastructure</li>
          <li>Networking infrastructure</li>
        </ul>

        <hr/>
        <h2>Website Development</h2>

        <h3>Client meetings</h3>
        <ul>
          <li>understand the general requirements</li>
          <li>proposal process to take requirements and outline a statement of work</li>
        </ul>
        <h3>Web Design</h3>
        <ul>
          <li>Work with the client to get a good design for their industry</li>
          <li>Iterate with the design until full approved.</li>
        </ul>
        <h3>Web Development</h3>
        <ul>
          <li>Code the various website assets into a workable public facing website</li>
          <li>Add additional features based on client meetings and proposal</li>
          <li>Store work assets in source repository</li>
          <li>Allow clients to own and access the source assets</li>
        </ul>
        <h3>Deployment</h3>
        <ul>
          <li>Setup of cloud services for continuously deploy services</li>
          <li>Use econmical hosting services to storing the running application</li>
          <li>Integrate additional services such as database, email, forms, blogs or anything else the client desires</li>
        </ul>


        <h3>Maintenance</h3>
        <ul>
          <li>Update the website based on changing content</li>
          <li>Respond to issues if the website were to go down</li>
          <li>Add feature as necessary.  This could result in future proposals</li>
        </ul>

        <hr/>

        <h2>Office 365 Setup</h2>

        <h3>Client Meeting</h3>
        <ul>
          <li>Discuss security requirements, number of users, total allocated space required.</li>
          <li>Explain microsoft licensing costs associated</li>
          <li>Proposal implementation and setup of services</li>
        </ul>


        <h3>Setup</h3>
        <ul>
          <li>Create accounts for all users</li>
          <li>Create structure for stored files required during client meetings</li>
          <li>Assign permissions to file / folder assets</li>
          <li>Create and add any additional required applications</li>
          <li>Setup domain and start email flow</li>
        </ul>


        <h3>Support</h3>
        <ul>
          <li>Break / fix support.</li>
          <li>Adding / removing users as necessary</li>
          <li>Updating permission as necessary</li>
        </ul>
        <hr/>

        <h3>Retail (Inventory and Reporting)</h3>
        <ul>
          <li>Accountant and Law Firm Software</li>
          <li>Hospitality Industry Solutions</li>
          <li>Private Practice and Group Practice Physicians</li>
          <li>Healthcare Support Systems</li>
          <li>Industrial and Commercial IT Services</li>
          <li>Government Software, Solutions and Support</li>
        </ul>

        <h3>CEO - Robert Chumley</h3>
        <p>
          Robert has been innovating technology solutions for 20+ years and is laser focused on enabling business growth through technical innovation.
        </p>
      </div>
    </div>
  </Layout>
)

export default TechnologyPage
